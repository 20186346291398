import React from 'react'
import { Link, navigate } from 'gatsby'
import { setUser, isLoggedIn } from '../services/auth'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const Login = () => {
  if (isLoggedIn()) navigate('/app/profile')
  return ( 
    <div className="flex h-screen">
      
      	<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-wallet"></i>
						</span>  
        
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={values => {
            const errors = {}
            if (!values.username) {
              errors.username = 'Required'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            ) {
              errors.username = 'Invalid email address'
            }
            if (!values.password) {
              errors.password = 'Required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await Auth.signIn(values.username, values.password)
              const user = await Auth.currentAuthenticatedUser()
              const userInfo = {
                ...user.attributes,
                username: user.username,
              }
              setUser(userInfo)
              navigate('/app/home')
            } catch (err) {
              console.log('error...: ', err);
              alert(err.message);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
            	<div class="container">
					<div class="row">
						<label htmlFor="username" className="col-md-6 col-sm-8 col-xs-8 col-xs-offset-3">Email
			                <Field
			                  type="email"
			                  name="username"
			                  className="form-control col-md-6 col-sm-8 col-xs-8"
			                />
			                <ErrorMessage
			                  name="username"
			                  component="div"
			                  className="text-red-500 text-sm"
			                />
						</label>
	              </div>
	              <div class="row">
		              <label
		                htmlFor="password"
		                className="col-md-6 col-sm-8 col-xs-8 col-xs-offset-3"
		              >
		                Paswoord
		                <Field
		                  type="password"
		                  name="password"
		                  className="form-control col-md-6 col-sm-8 col-xs-8"
		                />
		                <ErrorMessage
		                  name="password"
		                  component="div"
		                  className="text-red-500 text-sm"
		                />
		              </label>
	              </div>
	              <div class="row">
	              	<div class="col-sm-8 col-xs-8 col-xs-offset-3">
					  <button type="submit" disabled={isSubmitting} className="block center">
					  Inloggen
					  </button>
					  </div>
				  </div>	 
				</div>
            </Form>
          )}
        </Formik>
        <div class="row">
        <div class="col-sm-8 col-xs-8 col-xs-offset-5">
        <p>
        <Link to="/app/forgotpassword" className="text-brand-blue text-base">Paswoord vergeten of opnieuw instellen.</Link>
        </p>
        </div>
        </div>
        
      </div>
   
    </div>
  )
}

export default Login