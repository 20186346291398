import React from 'react'
import { Router } from '@reach/router'
import Layout from "../components/layout"
import PrivateRoute from '../components/privateRoute'
import Profile from '../components/profile'
import Login from '../components/login'
import Home from '../components/home'
import Reset from '../components/resetpassword'
import Forgot from '../components/forgotpassword'
import Newpassword from '../components/newpassword'
import SignUp from '../components/signup'

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/home" component={Home} />
      <PrivateRoute path="/app/profile" component={Profile} />
      <PrivateRoute path="/app/resetpassword" component={Reset} />
      <Forgot path="/app/forgotpassword" />
      <Login path="/app/login" />
      <Newpassword path="/app/newpassword" />
      <SignUp path="/app/signup" />
    </Router>
  </Layout>
)

export default App