import React from 'react'
import { Link, navigate } from 'gatsby'
import { setUser, isLoggedIn } from '../services/auth'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const Forgot = () => {
  /* if (isLoggedIn()) navigate('/app/profile') */
  return (
    <div className="flex h-screen">
    	<div className="flex h-screen">
			<div id="fh5co-product">
				<div class="container">
					<div class="row">
						<div className="sm:w-1/3 xl:w-1/4 bg-white m-auto mt-28 p-8 pb-2 rounded shadow-2xl">
							<div className="text-center text-2xl text-gray-800">Paswoord Resetten</div>
						        <Formik
						          initialValues={{ username: '' }}
						          validate={values => {
						            const errors = {}
						            if (!values.username) {
						              errors.username = 'Required'
						            } else if (
						              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
						            ) {
						              errors.username = 'Invalid email address'
						            }
						            return errors
						          }}
						          onSubmit={async (values, { setSubmitting }) => {
						            try {
							          const user = await Auth.forgotPassword(values.username)
						              navigate('/app/newpassword')
						            } catch (err) {
						              console.log('error...: ', err);
						              alert(err.message);
						            }
						          }}
						        >
						          {({ isSubmitting }) => (
						            <Form>
						              <label
						                htmlFor="username"
						                className="block mt-6 text-gray-500 text-base"
						              >
						                Email
						                <Field
						                  type="email"
						                  name="username"
						                  className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
						                />
						                <ErrorMessage
						                  name="username"
						                  component="div"
						                  className="text-red-500 text-sm"
						                />
						              </label>
						              <button
						                type="submit"
						                disabled={isSubmitting}
						                className="block w-full mb-10 mt-8  rounded px-6 py-1 border-gray-500 bg-green-500 hover:bg-green-700 text-white font-bold"
						              >
						                Paswoord Opnieuw instellen
						              </button>
						            </Form>
						          )}
						        </Formik>
								<ul>
								<li><p><Link to="/" className="text-brand-blue text-base">Indien u nog geen gebruikersnaam heeft, gelieve eerst een abonnement te nemen.</Link> </p> </li>
								<li><p>Na aankoop worden richtlijnen voor registratie en login doorgestuurd per email.</p> </li>
								<li><Link to="/app/signup" className="text-brand-blue text-base">Registratieformulier voor nieuwe gebruikers.</Link></li>
								</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Forgot