import React from 'react'
import { getUser } from '../services/auth'
import Layout from "../components/layout"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { API, graphqlOperation } from "aws-amplify"
import awsExports from "../aws-exports"
import * as queries from "../graphql/queries"

const Home = (total) => {
      	
  const [orders, setOrders] = useState([])
  
 	useEffect(() => {
    onPageRendered();
  	}, []);

  	const onPageRendered = async () => {
    getOrders();
  	};

  	const getOrders = async () => {
    
    const filter = {
    School: {
        eq: 'Crea16' // filter priority = 1
    }
	};
    
    //const getOrder = await API.graphql(graphqlOperation(queries.listOrders, {variables: { filter: filter}},{sort: {direction: 'asc',field: 'createdAt'}}));
    
    const getOrder = await API.graphql(graphqlOperation(queries.listOrders, {filter: { School: { eq: "Demosteunverkoop" }}}));
    
    
    
	const thisorder = getOrder.data.listOrders.items
	
	const sortedArray = thisorder.sort(function(a, b) {
	return new Date(a.createdAt) - new Date(b.createdAt)
	});
	
	//console.log(sortedArray);
	
	setOrders(sortedArray)
 	};
 	
 	
 	//console.log(totalsum)
 	
 	var total = ((orders.reduce((a,v) =>  a = a + v.OrderTotal , 0 ))).toFixed(2);

 
 	if(!orders.length){
	 	       var items = 
	 	       <p>
	 		   Er zijn nog geen bestellingen geplaatst.
	 		   </p>
        }else{
        }
		
  return (
	<div id="fh5co-product">
		<div className="container">	
			<div className="row">
				<div className="text md:w-2/6">
					<h1 className="title">Status Verkoop</h1>
						<h1 className="title">Totaal : {total}  </h1>
						
						<table class="table table-layout">
							<colgroup>
								<col span="1"/>
								<col span="1"/>
								<col span="1"/>
								<col span="1"/>
								<col span="1"/>
								<col span="1"/>
							</colgroup>
							<thead>
								<tr>
									<th>Order Id</th>
									<th>Datum</th>
									<th>Order Totaal</th>
									<th>Email</th>
									<th>Klas</th>
									<th>Leerling</th>
								</tr>
							</thead>
								<tbody>
								{orders.map(order => (
									
									<tr>
										<td>{order.OrderId}</td>
										<td>{new Date(order.createdAt).toLocaleDateString()}</td>
										<td>{new Intl.NumberFormat("nl-BE", {style: "currency",currency: "EUR",minimumFractionDigits: 2,maximumFractionDigits: 2}).format(order.OrderTotal)}</td>
										<td>{order.Email}</td>
										<td>{order.Class}</td>
										<td>{order.Pupil}</td>
									</tr>
									
								))}
								</tbody>
						</table>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Home