import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { isLoggedIn } from '../services/auth'

const SignUp = () => {
  if (isLoggedIn()) navigate('/app/profile')

  const [stage, setStage] = useState(0)

  return (
	<div>  
    <div className="flex h-screen">
    <div class="text-center">
					<div class="feature-center animate-box fadeIn animated-fast" data-animate-effect="fadeIn">
						<span class="icon">
							<i class="icon-wallet"></i>
						</span>
						<h3>Registreer</h3>
						<p>Registreer u hier met het emailadres van de school waarmee u de steunverkoop heeft geregistreerd.</p>
						<p>Kies een veilig paswoord.</p> 
						<p>Het paswoord moet tenminste uit 8 karakters bestaan, een nummer, een leesteken én een hoofdletter bevatten.</p>
        <Formik
          initialValues={{
            username: '',
            password: '',
            authCode: '',
          }}
          validate={values => {
            const errors = {}
            if (stage === 0 && !values.username) {
              errors.username = 'Verplicht veld'
            } else if (
              stage === 0 &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            ) {
              errors.username = 'Ongeldig Email adres'
            }
            if (stage === 0 && !values.password) {
              errors.password = 'Verplicht'
            }
            if (stage === 1 && !values.authCode) {
              errors.authCode = 'Verplicht'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('onSubmit')
            if (stage === 0) {
              try {
                await Auth.signUp(values.username, values.password)
                setStage(1)
                //alert('Gelieve uw email te consulteren voor de verificatiecode die u werd toegestuurd. Deze zal u in het volgende scherm moeten invoeren om uw registratie te vervolledigen.')
                console.log('Stage: ', stage)
              } catch (err) {
                console.log('error signing up...', err)
              }
            } else {
              try {
                await Auth.confirmSignUp(values.username, values.authCode)
                alert('U ben succesvol geregistreerd. Gelieve in te loggen met uw email en daarnet gekozen paswoord.')
                navigate('/app/login')
              } catch (err) {
                console.log('error confirming signing up...', err)
              }
            }
          }}
        >
          {({ isSubmitting }) => {
            if (stage === 0) {
              return (
                <Form>
                  <label
                    htmlFor="username"
                    className="block mt-6 text-gray-500 text-base"
                  >
                    Email
                    <Field
                      type="email"
                      name="username"
                      className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
                  
                  <label
                    htmlFor="password"
                    className="block mt-6 text-gray-500 text-base"
                  >
                    Password
                    <Field
                      type="password"
                      name="password"
                      className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="block w-full mb-10 mt-8 rounded px-6 py-1 border-gray-500 bg-green-500 hover:bg-green-700 text-white"
                  >
                    Registreer
                  </button>
                  
                </Form>
              )
            } else {
              return (
	            
                <Form>
                  <label
                    htmlFor="authCode"
                    className="block mt-6 text-gray-500 text-base"
                  >
                    Gelieve hier de verificatiecode in te vullen die u per mail toegezonden kreeg. 
                    <Field
                      type="text"
                      name="authCode"
                      className="block border-gray-300 border-2 rounded text-gray-700 text-sm w-full px-2 py-2"
                    />
                    <ErrorMessage
                      name="authCode"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="block w-full mb-10 mt-8 border-2 rounded px-6 py-1 border-gray-500 bg-green-500 hover:bg-green-700 text-white"
                  >
                    Bevestig en vervolledig de registratie.
                  </button>
                </Form>
              )
            }
          }}
        </Formik>
      </div>
	</div>
</div>	
</div>

  )
}

export default SignUp