import React from 'react'
import { getUser } from '../services/auth'
import Layout from "../components/layout"

const Profile = () => (
  <>
  	<div id="fh5co-product">
	
	</div>
  </>
)

export default Profile